import * as React from 'react';
import { AppBar, Container, IconButton, Typography, Menu, Avatar, MenuItem, Button } from '@mui/material';
import logo from '../../resources/2223.png';
import { useAuth } from '../../contexts/AuthContext';


const settings = [
    // 'Profile', 'Account', 'Dashboard',
    'Logout'
];

const NavBar = () => {
    const { userData, clubData, logout } = useAuth();
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = (setting: string) => {
        setAnchorElUser(null);
        switch (setting) {
            case 'Logout':
                logout();
                break;
        }
    };

    if (window.location.pathname.includes('report-final')) {
        return null;
    }

    return (
        userData !== null ? (
            <AppBar position="static" style={{ backgroundColor: '#D81157', zIndex: 1000, position: 'relative' }}>
                <Container maxWidth="xl">
                    <div className="d-flex justify-content-between">
                        <div className='p-2 bg-light'>
                            <img src={logo} className="logo" alt="logo" />
                        </div>
                        {userData && (<>
                            <div className='d-none d-md-flex'>
                                <div className='px-4 text-end pt-3'>
                                    <h4 className='p-0 m-0'>{userData?.m_fname} {' '} {userData?.m_lname}</h4>
                                    <h6 className='p-0 m-0'>{clubData?.c_name}</h6>
                                </div>
                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                    <Avatar alt={`${userData?.m_fname} ${userData?.m_lname}`} src="/static/images/avatar/2.jpg" />
                                </IconButton>
                                <Menu
                                    sx={{ mt: '45px' }}
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={handleCloseUserMenu}
                                >
                                    {settings.map((setting) => (
                                        <MenuItem key={setting} onClick={() => handleCloseUserMenu(setting)}>
                                            <Typography textAlign="center">{setting}</Typography>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </div>
                            <div className='d-md-none d-flex align-items-center'>
                                <Button style={{ color: '#fff' }} onClick={() => handleCloseUserMenu('Logout')} variant="text">Logout</Button>
                            </div></>
                        )}
                    </div>
                </Container>
            </AppBar>
        )
            : null
    );
};
export default NavBar;