import { useAuth } from '../../../contexts/AuthContext';

export const ZeroBidsComponent = () => {
    const { allBiddedProjectsGrouped, projectsListGlobal } = useAuth();

    return (
        <>
            <div className='border bg-light border-light mb-3 p-3'>
                <h4 className='mb-3'> Projects with Slots Available</h4>
                <div className='row g-1'>
                    {
                        projectsListGlobal.map(proj => !(allBiddedProjectsGrouped[proj.project_name] || []).length && (
                            <div key={proj.project_id || proj.project_name} className="col-lg-4 col-sm-6 col-12">
                                <div className="card m-0 p-0 border-0">
                                    <div className='card-body m-0 p-0'>
                                        <div
                                            className="border rounded d-flex justify-content-center align-items-center"
                                            style={{ minHeight: 44, backgroundColor: '#FFC7CE', color: '#9C0006' }}
                                        >
                                            <h6 className='m-0 px-1 text-center' style={{ fontSize: '14px' }}>{proj.project_name}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    )
}