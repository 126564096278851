import { useEffect } from 'react';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { BiddingControlComponent } from './modules/Admin/Bidding/BiddingControlComponent';
import { LoginComponent } from './modules/Login/LoginComponent';
import { MainPageComponent } from './modules/MainPage/MainPageComponent';
import NonGuardedRoute from './routeGurads/NonGuardedRoute';
import NavBar from './shared/navbar/NavbarComponent';
import GuardedRoute from './routeGurads/GuardedRoute';
import AdminGuardedRoute from './routeGurads/AdminGuardedRoute';
import initializeFirebase from './services/firebaseService';
import { ReportComponent } from './modules/Admin/Report/ReportComponent';
import { MainDashboardComponent } from './modules/Dashboard/MainDashboardComponent';
import './App.css';

function App() {
    useEffect(() => {
        initializeFirebase();
    }, []);

    return (
        <div className="App">
            {/* <div className="noMobile"> */}
            <NavBar />
            <div className=''>
                <BrowserRouter>
                    <Routes>
                        <Route path="/login" element={<NonGuardedRoute element={<LoginComponent />} />} />
                        <Route path="/" element={<GuardedRoute element={<MainPageComponent />} />} />
                        <Route path="/dashboard" element={<GuardedRoute element={<MainDashboardComponent />} />} />
                        <Route path="/superuser/bidding" element={<AdminGuardedRoute element={<BiddingControlComponent />} />} />
                        <Route path="/report-final" element={<AdminGuardedRoute element={<ReportComponent />} />} />
                    </Routes>
                </BrowserRouter>
            </div>
            {/* </div> */}
            {/* <div className="forMobile m-3 text-center">
        <h5 className='text-secondary'> Please use a laptop to bid! Thank You! </h5>

      </div> */}
        </div>
    );
}

export default App;