import axios from 'axios';
import { arrayUnion, increment, serverTimestamp } from 'firebase/firestore';
import { createClub, createUser, createProject, getAllDocuments, updateProject, updateClub, removeBid } from './firebaseService';

import { club_data } from '../constants/club_data';
import { dummyProjects, dcms, projects } from '../constants/projects';
import { user_data } from '../constants/user_data';
import { VERSION } from '../constants/version_data';
import { Club } from '../models/Club';
import Project from '../models/Project';
import { User } from '../models/User';


export const addPresAndSecs = async () => {
    try {
        // const users = (await axios.get(`https://rotaract3220.org/rmis/${VERSION}/api/get-secs-and-pres`)).data?.users as any[];
        const users = user_data as User[];

        const formattedUsers = users.map(e => ({
            // table_id: e.table_id,
            club_id: e.club_id,
            m_fname: e.m_fname,
            m_lname: e.m_lname,
            // m_oname: e.m_oname,
            // m_nickname: e.m_nickname,
            // m_username: (e.m_username as string).toLowerCase(),
            m_password: e.m_password,
            // full_name: e.full_name,
            // card_name: e.card_name,
            email: (e.email as string).toLowerCase(),
            // nic_pp: e.nic_pp,
            role_id: e.role_id
        } as User));

        let successCount = 0;

        formattedUsers.forEach((user, index) => {
            try {
                createUser(user, user.email)
                    .then(() => successCount++)
                    .catch(e => console.log(index, e))
                    .finally(() => {
                        console.log(`Completed ${successCount}/${formattedUsers.length}`)
                        if (successCount === formattedUsers.length) {
                            alert('Users added successfully');
                        }
                    });
            } catch (e) {
                console.log(index, user, e);
            }
        });
    } catch (err) {
        console.log(err);
    }
}

export const addClubCoins = async () => {
    try {
        // const clubs = (await axios.get(`https://rotaract3220.org/rmis/${VERSION}/api/get-clubs`)).data?.clubs as Club[];
        const clubs = club_data as Club[];

        const formattedClubs = clubs.map(club => {
            return {
                ...club,
                coins: 100,
                lastUpdated: serverTimestamp()
            } as Club;
        })

        let successCount = 0;

        formattedClubs.forEach((club, index) => {
            try {
                createClub(club, club.club_id)
                    .then(() => successCount++)
                    .catch(e => console.log(index, e))
                    .finally(() => {
                        console.log(`Completed ${successCount}/${clubs.length}`)
                        if (successCount === clubs.length) {
                            alert('Coins Added to Clubs Succesfully!');
                        }
                    });
            } catch (e) {
                console.log(index, club, e);
            }
        });
    } catch (err) {
        console.log(err);
    }
}

export const addProjects = async (type: 'dummy' | 'dcm' | 'district') => {
    try {
        let successCount = 0;

        const projectsTemp = type === 'dummy' ? dummyProjects : type === 'dcm' ? dcms : projects;

        projectsTemp.map(e => ({
            ...e,
            min_bid: 0
        } as Project)).forEach((project, index) => {
            try {
                createProject(project)
                    .then(() => successCount++)
                    .catch(e => console.log(index, e))
                    .finally(() => {
                        console.log(`Completed ${successCount}/${projectsTemp.length}`);
                        if (successCount === projectsTemp.length) {
                            alert('Projects added succesfully');
                        }
                    });
            } catch (e) {
                console.log(index, project, e);
            }
        });
    } catch (err) {
        console.log(err);
    }
}

export const groupBy = (key: string) => (array: any[]) =>
    array.reduce((objectsByKeyValue, obj) => {
        const value = obj[key];
        objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
        return objectsByKeyValue;
    }, {});

export const sortList = (a: any, b: any) => {
    console.log('a', a.id, a.timestamp, 'b', b.id, a.timestamp);
    return b.bid_amount - a.bid_amount || (a.timestamp?.toMillis() || 0) - (b.timestamp?.toMillis() || 0)
};

export const startNextRound = async () => {
    const groupByProject = groupBy('projectName');
    try {
        const [projectsData, bidsData] = await Promise.all([getAllDocuments('projects'), getAllDocuments('bids')]);

        // get all projects
        const projects = projectsData.docs.map(e => ({ project_id: e.id, ...e.data() } as Project));
        // get all bids
        const allBids = bidsData.docs.map(e => ({ id: e.id, ...e.data() }));

        if (!projects.length) {
            console.log('No projects existing');
            return;
        }

        if (!allBids.length) {
            console.log('No Bids existing');
            return;
        }

        // group bids by projectName
        const projectsBidded = groupByProject(allBids) as {
            [k: string]: any[]
        };

        const projectsFinal = {} as { [k: string]: any[] };
        const clubsFinal = {} as { [k: string]: { coins: number, projectsOwned: any[] } };

        // loop for each project
        Object.entries(projectsBidded).forEach(([projectName, clubsBidded]) => {
            // select projectDoc from projects array using projectName
            const project = projects.find(e => e.project_name === projectName);

            // if a project exist
            if (project) {
                // loop for exisiting bids for that project
                clubsBidded.sort(sortList).forEach((e, i) => {
                    // if there no entry for club in clubsFinal object set initial entry
                    if (!clubsFinal[e.clubId]) {
                        clubsFinal[e.clubId] = {
                            coins: 0,
                            projectsOwned: []
                        };
                    }

                    // if project is owned by the club
                    if (i < project.maximum_clubs) {
                        // add the club entry to projectFinal object
                        const projectId = project.project_id || '';
                        if (!projectsFinal[projectId]) {
                            projectsFinal[projectId] = [];
                        }

                        projectsFinal[projectId].push(e);

                        //add the project entry to clubFinal object
                        clubsFinal[e.clubId].projectsOwned.push(e);
                        // if project is not owned by the club
                    } else {
                        // add the spent coins amount to clubsFinal object;
                        clubsFinal[e.clubId].coins += e.bid_amount;
                    }
                })
            }
        })

        let completedProjects = 0;
        const projectEntries = Object.entries(projectsFinal);

        // Update all project entries
        projectEntries.forEach(([projectId, clubsOwned]) => {
            updateProject(projectId, {
                clubsOwned: arrayUnion(...clubsOwned),
                maximum_clubs: increment(-clubsOwned.length)
            }).then(() => {
                completedProjects++;
            }).catch(e => {
                console.log('Something went wrongin project update', e);
            }).finally(() => {
                console.log(`Completed projects ${completedProjects}/${projectEntries.length}`);
            });
        });

        let completedClubs = 0;
        const clubEntries = Object.entries(clubsFinal);

        // Update all club entries
        clubEntries.forEach(([clubId, clubData]) => {
            updateClub(clubId, {
                coins: increment(clubData.coins),
                projectsOwned: arrayUnion(...clubData.projectsOwned)
            }).then(() => {
                completedClubs++;
            }).catch(e => {
                console.log('Something went wrong in club update', e);
            }).finally(() => {
                console.log(`Completed clubs ${completedClubs}/${clubEntries.length}`);
            });
        });

        let completedBids = 0;

        // remove all bids from the collection
        allBids.forEach(bid => {
            removeBid(bid.id).then(() => {
                completedBids++;
            }).catch(e => {
                console.log('Something went wrong in bid update', e);
            }).finally(() => {
                console.log(`Removed bids ${completedBids}/${allBids.length}`);
            });
        });

        alert('Process is started');
    } catch (e) {
        console.log('Something went wrong', e);
    }
}

export const removeAllBids = async () => {
    if (window.confirm('Are you sure?')) {
        try {
            const allBids = (await getAllDocuments('bids')).docs.map(e => ({ id: e.id, ...e.data() }));

            if (!allBids.length) {
                console.log('No Bids existing');
                return;
            }

            let completedBids = 0;
            // remove all bids from the collection
            allBids.forEach((bid: any) => {
                removeBid(bid.id).then(() => {
                    completedBids++;
                }).catch(e => {
                    console.log('Something went wrong in bid update', e);
                }).finally(() => {
                    console.log(`Removed bids ${completedBids}/${allBids.length}`);
                });
            });
        } catch (e) {
            console.log(e);
        }
    }
}