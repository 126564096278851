import { useState, useEffect } from 'react';
import { Unsubscribe } from 'firebase/database';
import { DashboardDrawerComponent } from './components/DashboardDrawerComponent';
import { OwnedProjectsComponent } from './components/OwnedProjectsComponent';
import { ProjectListComponent } from './components/ProjectListComponent';
import { MyBiddingsComponent } from './components/MyBiddingsComponent';
import { ZeroBidsComponent } from './components/ZeroBidsComponent';
import { useAuth } from '../../contexts/AuthContext';
import TimerIcon from '@mui/icons-material/Timer';
import PaidIcon from '@mui/icons-material/Paid';
import { projectDataAll } from '../../data/datafile';
import { listenCountdownTime, listenServerTimeOffset } from '../../services/firebaseService';

import background from '../../resources/bg.png';

export const MainPageComponent = () => {
    const { userData, clubData } = useAuth();

    const [projects, setProjects] = useState<any>([]);
    const [serverTimeOffset, setServerTimeOffset] = useState(0);
    const [intervalFn, setIntervalFn] = useState<any>(null);
    const [started, setStarted] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [timeLoaded, setTimeLoaded] = useState(0);

    useEffect(() => {
        let unsubscribe: Unsubscribe;

        setProjects(projectDataAll);
        // Initiate time offset listner on firebase realtime database
        unsubscribe = listenServerTimeOffset((snapshot) => setServerTimeOffset(snapshot.val()));
        return () => {
            unsubscribe();
        }
    }, [])

    useEffect(() => {
        let unsubscribe: Unsubscribe;

        if (userData) {
            // Initiate start time and seconds count change listner on firebase realtime database
            unsubscribe = listenCountdownTime((snapshot) => {
                // Increment timeLoaded when new time arrived
                setTimeLoaded(val => {
                    // time loaded more than 1 time refresh the window
                    if (val >= 1) {
                        window.location.reload();
                    }
                    return 1;
                });

                // set the seconds count and starting time
                const seconds = snapshot.val().seconds as number;
                const startAt = snapshot.val().startAt as number;

                // if interval function is set then clear th interval
                intervalFn && clearInterval(intervalFn);
                // get the time now
                let oldTime = Date.now();
                let newTime: number;

                // initiate new interval function
                const interval = setInterval(() => {
                    // get the current time
                    newTime = Date.now();
                    // if absolute difference between newTime and oldTime is more than 2000
                    // then refresh the page and get the new offset time
                    if (newTime - oldTime > 2000 || oldTime - newTime > 2000) {
                        window.location.reload();
                        return;
                    }

                    // set oldTime
                    oldTime = Date.now();
                    // get the timeLeft for start using clients current date and starting time
                    let timeLeft = parseInt(((startAt - Date.now() - serverTimeOffset) / 1000).toFixed(0));

                    // set countdown timer
                    const temp = Math.floor(timeLeft / 60);
                    let hours = Math.floor(temp / 60) >= 10 ? Math.floor(temp / 60).toString() : "0" + Math.floor(temp / 60);
                    let mins = temp % 60 >= 10 ? (temp % 60).toString() : "0" + temp % 60;
                    const secs = timeLeft % 60 >= 10 ? timeLeft % 60 : '0' + timeLeft % 60;

                    let disp = "Starting in " + hours + ":" + mins + ":" + secs;

                    // if time is Left more and started flag set to true
                    // then set start flag to false
                    // and show countdown timer in blank screen
                    if (timeLeft > 0 && started) {
                        setStarted(false);
                        const mins = Math.floor(timeLeft / 60);
                        const secs = timeLeft % 60 >= 10 ? timeLeft % 60 : '0' + timeLeft % 60;
                        disp = "Starting in " + mins + ":" + secs;
                    }
                    // else if time has equal or passed the starting time then
                    else if (timeLeft <= 0) {
                        // if started flag set to false
                        // then set start flag to true
                        !started && setStarted(true);
                        // get the time left to end the countdown by adding seconds count at the end
                        timeLeft = parseInt(((startAt - Date.now() - serverTimeOffset) / 1000 + seconds).toFixed(0));
                        // set countdown timer accordingly
                        const mins = Math.floor(timeLeft / 60);
                        const secs = timeLeft % 60 >= 10 ? timeLeft % 60 : '0' + timeLeft % 60;
                        disp = mins < 0 ? "Time is Up" : mins + ":" + secs;
                    }

                    // set countdown to element using getElementById
                    // don't use useState function here to set the coundown value,
                    // since it will render page 5 times a seconds
                    // which can cause performance issue or a infinite re-render
                    const elem = document.getElementById('countdown');
                    if (disp === "Time is Up") {
                        setIsActive(false);
                    } else {
                        setIsActive(true);
                    }
                    elem && (elem.innerText = disp);
                    // run the interval function once every 200 ms
                }, 200);
                // set the interval function to intervalFn state
                setIntervalFn(interval);
            });
        }

        return () => {
            unsubscribe && unsubscribe();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serverTimeOffset])

    return (
        <>
            {started ? (
                <>
                    <DashboardDrawerComponent />
                    <div className="container-fluid">
                        <div className="mx-md-5 mt-md-5 mx-1 mt-1 d-flex justify-content-between rounded">
                            <h2 className='mt-2 text-dark'><strong>Bidding Dashboard</strong></h2>
                            <div className='d-flex py-2 text-light'>
                                <div className='d-flex rounded align-items-center px-2 mx-2' style={{ backgroundColor: '#D81157' }}>
                                    <TimerIcon />
                                    <h4 className='mx-2 mt-2'> <span id="countdown"></span> </h4>
                                </div>
                                <div className='d-flex rounded align-items-center px-2' style={{ backgroundColor: '#D81157' }}>
                                    <PaidIcon />
                                    <h4 className='mx-2 mt-2' id="coins"> {clubData?.coins || 0} </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='m-md-5 m-0 row'>
                        <div className="col-lg-6 col-12 mb-3">
                            {projects && <ProjectListComponent isActive={isActive} />}
                        </div>
                        <div className="col-lg-6 col-12">
                            <MyBiddingsComponent isActive={isActive} />
                            <ZeroBidsComponent />
                            <OwnedProjectsComponent />
                        </div>
                    </div>
                </>
            ) : (
                <div className="d-flex justify-content-center text-white" style={{ position: 'relative', height: "80vh" }}>
                    <div className='d-flex rounded mt-5 px-2 mx-2' style={{ backgroundColor: '#D81157', position: 'absolute', zIndex: 10, minHeight: 50 }}>
                        <TimerIcon className='mt-3' />
                        <h2 className='mx-2 mt-2'> <span id="countdown"></span> </h2>
                    </div>
                    <div className="w-100 align-items-center" style={{ height: '100vh' }}>
                        <div className='w-100'
                            style={{
                                height: '100vh',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                overflow: 'hidden',
                                position: 'absolute',
                            }}
                        >
                            <img src={background} alt="img"
                                style={{
                                    height: '100%',
                                    width: '100%',
                                    objectFit: 'cover'
                                }}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}