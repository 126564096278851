import React, { useState, useEffect } from 'react';
import { serverTimestamp } from 'firebase/firestore';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useAuth } from '../../../contexts/AuthContext';
import { removeBid, updateBid, updateClubCoins } from '../../../services/firebaseService';

export const MyBiddingsComponent = (props: any) => {
    const tableHeaders = ['Project Name', 'Current Max Bid', 'Your Bid', 'Action'];
    const { isActive } = props;
    const { clubData, allBiddedProjects, allBiddedProjectsGrouped, setMyBidsGlobal } = useAuth();
    const [myBids, setMyBids] = useState<any[]>([]);
    const [bidAmounts, setBidAmounts] = useState<any>({});

    useEffect(() => {
        if (clubData && clubData?.club_id && allBiddedProjects) {
            const bids = allBiddedProjects.filter(e => e.clubId === clubData.club_id)
            setMyBids(bids);
            setMyBidsGlobal(bids);
            setBidAmounts(Object.fromEntries(bids.map((e: any) => [e.projectId, e.bid_amount])));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clubData, allBiddedProjects]);

    const updateBidding = (e: any, project: any) => {
        e.preventDefault();
        try {
            updateBid(`${project.projectId}-${clubData?.club_id || ''}`, {
                bid_amount: bidAmounts[project.projectId],
                timestamp: serverTimestamp()
            });
            updateClubCoins(clubData?.club_id || '', bidAmounts[project.projectId] - project.bid_amount);
        }
        catch (e) {
            console.log(e);
        }
    }

    const removeBidding = (e: any, project: any) => {
        e.preventDefault();
        removeBid(`${project.projectId}-${clubData?.club_id || ''}`);
        updateClubCoins(clubData?.club_id || '', -project.bid_amount);
    }

    const handleChange = (val: string, project: any) => {
        const formattedVal = (val === '' ? 0 : parseInt(val));
        if (formattedVal >= 0) {
            setBidAmounts({
                ...bidAmounts,
                [project.projectId]: formattedVal > ((clubData?.coins || 0) + project.bid_amount) ? ((clubData?.coins || 0) + project.bid_amount) : formattedVal
            });
        }
    }

    return (
        <>
            <div className='border bg-light border-light mb-3 p-3'>
                <h4 className='mb-3'> My Bids</h4>
                <TableContainer component={Paper}>
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                {tableHeaders && tableHeaders.map((head: String, index: number) => {
                                    return (
                                        <TableCell className='px-1 px-md-3' align={index === 0 ? 'left' : 'right'} key={index}><strong>{head}</strong></TableCell>
                                    )
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {myBids.map((project: any, index: number) => (
                                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell className='px-1 px-md-3' component="th" scope="row" style={{ width: '260px' }}>{project.projectName}</TableCell>
                                    <TableCell className='px-1 px-md-3' align='right' component="th" scope="row">{allBiddedProjectsGrouped[project.projectName] ? allBiddedProjectsGrouped[project.projectName][0].bid_amount : 0}</TableCell>
                                    <TableCell className='px-1 px-md-3' align='right' component="th" scope="row">{project.bid_amount}</TableCell>
                                    <TableCell className='px-1 px-md-3' align='right' component="th" scope="row">
                                        <div className="d-flex" style={{ marginLeft: 'auto', width: '150px' }}>
                                            <div className="input-group input-group-sm mx-2" style={{ width: '120px' }}>
                                                <input type="number" min={0} max={100}
                                                    onChange={(e) => handleChange(e.target.value, project)}
                                                    value={bidAmounts[project.projectId]}
                                                    className="form-control" />
                                                <div className="input-group-append">
                                                    <button
                                                        disabled={!isActive}
                                                        className="btn btn-sm btn-success"
                                                        type="button"
                                                        onClick={(e) => updateBidding(e, project)}
                                                    >
                                                        <EditIcon className='p-0 m-0' style={{ fontSize: '18px' }} />
                                                    </button>
                                                </div>
                                            </div>
                                            <button
                                                disabled={!isActive}
                                                className="btn btn-sm btn-danger"
                                                type="button"
                                                onClick={(e) => removeBidding(e, project)}
                                            >
                                                <RemoveCircleIcon className='p-0 m-0' style={{ fontSize: '18px' }} />
                                            </button>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {!myBids.length && (
                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell colSpan={5} className='text-center text-muted'> none to show</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </>
    )
}