import { useAuth } from '../../../contexts/AuthContext';
import { sortList } from '../../../services/adminService';

export const OwnedProjectsComponent = (props: any) => {
    const { projectsListGlobal, allBiddedProjectsGrouped, myBidsGlobal, clubData } = useAuth();

    let projectsCount = 0;

    return (
        <>
            <div className='border bg-light border-light mb-3 p-3'>
                <h4 className='mb-3'> Owned Projects (From Current Round)</h4>
                <div className='row g-1'>
                    {
                        myBidsGlobal.map(bid => {
                            const index = (allBiddedProjectsGrouped[bid.projectName] || []).sort(sortList).findIndex((e: any) => e.id === bid.id);
                            const project = projectsListGlobal.find(e => e.project_id === bid.projectId);

                            if (project && index < project.maximum_clubs) {
                                projectsCount++;
                                return (
                                    <div key={bid.projectId} className="col-lg-4 col-sm-6 col-12">
                                        <div className="card mb-0 p-0 border-0">
                                            <div className='card-body m-0 p-0'>
                                                <div className="border rounded d-flex justify-content-center align-items-center" style={{ minHeight: 44, backgroundColor: '#C6EFCE', color: '#006100' }}>
                                                    <h6 className='my-1 px-2 text-center' style={{ fontSize: '14px' }}>{bid.projectName}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            return null;
                        })
                    }
                    {
                        !projectsCount && (<div className='text-secondary text-center'>No projects to list.</div>)
                    }
                </div>
            </div>
            <div className='border bg-light border-light mb-5 mb-md-3 p-3'>
                <h4 className='mb-3'> Owned Projects (From Previous Rounds)</h4>
                <div className='row'>
                    {
                        clubData?.projectsOwned && clubData?.projectsOwned.length > 0 ? clubData?.projectsOwned.map(bid => (
                            <div key={bid.projectId} className="col-lg-4 col-sm-6 col-12">
                                <div className="card mb-2 p-0 border-0">
                                    <div className='card-body m-0 p-0'>
                                        <div className="border rounded d-flex justify-content-center align-items-center" style={{ minHeight: 44, backgroundColor: '#C6EFCE', color: '#006100' }}>
                                            <h6 className='my-1 px-2 text-center' style={{ fontSize: '14px' }}>{bid.projectName}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )) : (
                            <div className='text-secondary text-center'>No projects to list</div>
                        )
                    }
                </div>
            </div>
        </>
    )
}