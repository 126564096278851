import { ChangeEvent, FormEvent, useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import background from '../../resources/bg.png';
import logo from '../../resources/2223.png';

export const LoginComponent = () => {
    const { login } = useAuth();

    const [payload, setPayload] = useState({ email: '', password: '' });
    const [alert, setAlert] = useState('');
    const [loading, setLoading] = useState(false);

    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        setPayload({
            ...payload,
            [e.target.name]: e.target.value
        });
    }

    const handleLogin = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        setAlert('');

        if (!payload.email) {
            setAlert('Email cannot be empty. Please provide a valid email');
            return;
        }

        if (!payload.password) {
            setAlert('Password cannot be empty. Please provide a valid password.');
            return;
        }

        login(payload.email, payload.password, setAlert);
    }

    return (
        <div className='w-100 text-center' style={{ height: '100vh' }}>
            <div className='w-100'
                style={{
                    height: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    overflow: 'hidden',
                    position: 'absolute',
                }}
            >
                <img src={background} alt="img"
                    style={{
                        height: '100%',
                        width: '100%',
                        objectFit: 'cover'
                    }}
                />
            </div>
            <div className='d-flex justify-content-center align-items-center h-100'>
                <div>
                    <div className="card mx-3" >
                        <div className="card-body text-center">
                            <img className='my-4 img-fluid' src={logo} alt="logo" />
                            <p className="card-title mt-3">Please provide your user information</p>
                            <form className='text-left' onSubmit={handleLogin}>
                                <div className="form-group mt-4">
                                    <input type="email" className="form-control" name="email" onChange={handleOnChange} value={payload.email} placeholder="Email address" />
                                </div>
                                <div className="form-group mt-4">
                                    <input type="password" className="form-control" name="password" onChange={handleOnChange} value={payload.password} placeholder="Password" />
                                </div>
                                <button type="submit" className="btn btn-lg btn-primary w-100 mt-5">
                                    {loading ? (
                                        <div className="spinner-border text-light" role="status">
                                            <span className="sr-only"></span>
                                        </div>) : 'Sign me in'}
                                </button>

                                {alert !== '' && (<div className='text-danger'>{alert}</div>)}
                                {/* <input type="email" value={email} className="form-control" placeholder="Email Address" onChange={handleEmail} />
                                </div>
                                <div className="form-group mt-4">
                                    <input type="password" value={pwd} className="form-control" placeholder="Password" onChange={handlePwd} />
                                </div>
                                <button type="submit" onClick={handleSubmit} className="btn btn-primary w-100 mt-5">Sign me in</button> */}
                            </form>
                        </div>
                    </div>
                    <div className="card bg-transparent border-0 text-center">
                        <div className="card-body text-white">
                            <p className='m-0'>Don't have an account? Contact your President or DRR </p>
                            <p className='m-0'>© Rotaract District Digital Communications and Technology </p>
                            <p className='m-0'>Development 2017 - 2024</p>
                        </div>
                    </div>
                </div>
            </div>
        </ div>
    )
};