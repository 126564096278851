export const projectDataAll = {
    "nonBidded": [],
    "owned": [],
    "projects": [
        {
            "project_id": 1,
            "project_name": "DCM - July",
            "maximum_clubs": 4,
            "min_bid": 0,
            "avenue": "Club Service",
            "zone": "0"
        },
        {
            "project_id": 2,
            "project_name": "DCM - August",
            "maximum_clubs": 4,
            "min_bid": 0,
            "avenue": "Club Service",
            "zone": "0"
        },
        {
            "project_id": 3,
            "project_name": "DCM - September",
            "maximum_clubs": 4,
            "min_bid": 0,
            "avenue": "Club Service",
            "zone": "0"
        },
        {
            "project_id": 4,
            "project_name": "DCM - October",
            "maximum_clubs": 4,
            "min_bid": 0,
            "avenue": "Club Service",
            "zone": "0"
        },
        {
            "project_id": 5,
            "project_name": "DCM - November",
            "maximum_clubs": 4,
            "min_bid": 0,
            "avenue": "Club Service",
            "zone": "0"
        },
        {
            "project_id": 6,
            "project_name": "DCM - December",
            "maximum_clubs": 4,
            "min_bid": 0,
            "avenue": "Club Service",
            "zone": "0"
        },
        {
            "project_id": 7,
            "project_name": "DCM - January",
            "maximum_clubs": 4,
            "min_bid": 0,
            "avenue": "Club Service",
            "zone": "0"
        },
        {
            "project_id": 8,
            "project_name": "DCM - February",
            "maximum_clubs": 4,
            "min_bid": 0,
            "avenue": "Club Service",
            "zone": "0"
        },
        {
            "project_id": 9,
            "project_name": "DCM - March",
            "maximum_clubs": 4,
            "min_bid": 0,
            "avenue": "Club Service",
            "zone": "0"
        },
        {
            "project_id": 10,
            "project_name": "DCM - April",
            "maximum_clubs": 4,
            "min_bid": 0,
            "avenue": "Club Service",
            "zone": "0"
        },
        {
            "project_id": 11,
            "project_name": "DCM - May",
            "maximum_clubs": 4,
            "min_bid": 0,
            "avenue": "Club Service",
            "zone": "0"
        },
        {
            "project_id": 12,
            "project_name": "DCM - June",
            "maximum_clubs": 4,
            "min_bid": 0,
            "avenue": "Club Service",
            "zone": "0"
        }
    ],
    "maxbids": {
        "1": "100",
        "2": "100",
        "3": "100",
        "4": "100",
        "5": "100",
        "6": "100",
        "7": "100",
        "8": "100",
        "9": "100",
        "10": "100",
        "11": "100",
        "12": "100"
    }
}