import { useEffect } from 'react';
import { Unsubscribe } from 'firebase/database';
import { DashboardComponent } from './DashboardComponent';
import { useAuth } from '../../contexts/AuthContext';
import { listenProjectChnages } from '../../services/firebaseService';
import Project from '../../models/Project';

export const MainDashboardComponent = () => {
    const { setProjectsListGlobal } = useAuth();

    useEffect(() => {
        let unsubscriber: Unsubscribe;

        unsubscriber = listenProjectChnages({
            next: (snapshot) => {
                const projectsArr = snapshot.docs.map(doc => {
                    return {
                        project_id: doc.id,
                        ...doc.data()
                    } as Project;
                });
                setProjectsListGlobal(projectsArr);
            },
            error: (err) => console.log(err)
        });

        return () => {
            unsubscriber && unsubscriber();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="card-body p-3">
            <DashboardComponent />
        </div>
    )
}