import PaidIcon from '@mui/icons-material/Paid';
import { useAuth } from '../../contexts/AuthContext';

export const DashboardComponent = () => {
    const { projectsListGlobal, allBiddedProjectsGrouped } = useAuth();

    const sortList = (a: any, b: any) => {
        return b.bid_amount - a.bid_amount || a.timestamp - b.timestamp
    };

    return (
        <div className='row'>
            {projectsListGlobal.map((proj, index) => {
                return (
                    <div key={index} className="col-lg-3 col-sm-6 col-12 my-2">
                        <div className="card m-0 p-0 border-0">
                            <div className='card-body m-0 p-1'>
                                <div className="border rounded mb-1" style={{ backgroundColor: '#D81157' }}>
                                    <h6 className='my-1 px-2 text-white'>{proj.project_name}</h6>
                                </div>
                                {(allBiddedProjectsGrouped[proj.project_name] || []).sort(sortList).map((club: any, indexN: number) => {
                                    let b_color = indexN > (proj.maximum_clubs - 1) ? '#FFC7CE' : '#C6EFCE';
                                    let f_color = indexN > (proj.maximum_clubs - 1) ? '#9C0006' : '#006100';
                                    return (
                                        <div key={indexN} className="border rounded mb-1" style={{ backgroundColor: b_color, color: f_color }}>
                                            <div className='px-2 py-1 d-flex'>
                                                <div className="">
                                                    <h6 className='p-0 m-0' style={{ fontSize: '13px' }}>{club.clubName}</h6>
                                                </div>
                                                <div className="d-flex justify-content-between" style={{ paddingLeft: '3px', marginLeft: 'auto' }}>
                                                    <h6 className='my-0 mx-2' style={{ fontSize: '13px' }}>
                                                        {club.bid_amount}
                                                        <PaidIcon style={{ fontSize: '13px', marginTop: '-3px', marginLeft: '1px' }} />
                                                    </h6>
                                                    {/* <h6 className='m-0' style={{fontSize:'13px'}}>
                                                        {club.timestamp?.toDate().toTimeString().split('GMT')[0].trim()}
                                                        <TimerIcon style={{fontSize:'13px', marginTop:'-3px', marginLeft : '1px'}} /></h6> */}
                                                </div>
                                                {/* <p className='p-0 m-0'>{club.clubName} - {club.bid_amount}</p>
                                                <p>{club.timestamp?.toDate().toTimeString().split('GMT')[0].trim()}</p> */}
                                            </div>
                                        </div>
                                    )
                                })}
                                {
                                    !(allBiddedProjectsGrouped[proj.project_name] || []).length && (
                                        <div className="border rounded mb-1 bg-light text-secondary">
                                            <div className='px-2 d-flex justify-content-center'>
                                                <p className='p-0 me-3 mb-0'>No bids yet</p>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}