import { useState } from 'react';
import { Global } from '@emotion/react';
import { SpeedDial, SpeedDialIcon, SwipeableDrawer, Box } from '@mui/material';
import { DashboardComponent } from '../../Dashboard/DashboardComponent';

export const DashboardDrawerComponent = () => {
    const [drawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen)
    }

    return (
        <>
            <div className='h-100'>
                <Global styles={{ '.MuiDrawer-root > .MuiPaper-root': { height: `calc(50% - 56px)`, overflow: 'visible', } }} />
                <SwipeableDrawer
                    anchor="bottom"
                    open={drawerOpen}
                    onClose={toggleDrawer}
                    onOpen={toggleDrawer}
                    disableSwipeToOpen={false}
                    ModalProps={{
                        keepMounted: true,
                    }}
                >
                    <Box className='text-white'
                        onClick={toggleDrawer}
                        style={{ backgroundColor: '#D81157' }}
                        sx={{
                            position: 'absolute',
                            top: -56,
                            borderTopLeftRadius: 8,
                            borderTopRightRadius: 8,
                            visibility: 'visible',
                            right: 0,
                            left: 0,
                        }}
                    >
                        <Box sx={{ transform: 'translateZ(0px)', flexGrow: 1 }}>
                            <SpeedDial
                                ariaLabel="SpeedDial basic example"
                                sx={{ position: 'fixed', bottom: 16, right: 16 }}
                                icon={<SpeedDialIcon />}
                                onClick={toggleDrawer}
                                FabProps={{
                                    sx: {
                                        bgcolor: '#D81157',
                                        '&:hover': {
                                            bgcolor: '#D81157',
                                        }
                                    }
                                }}
                            >
                            </SpeedDial>
                        </Box>
                        <h5 className='text-center m-3'>View Dashboard</h5>
                    </Box>
                    <Box className='bg-white pt-3' sx={{ px: 2, pb: 2, height: '100%', overflowY: 'scroll' }}>
                        <DashboardComponent />
                    </Box>
                </SwipeableDrawer>
            </div>
        </>
    )
}