import { useEffect, useState } from 'react'
import Project from '../../../models/Project';
import { getAllDocuments } from '../../../services/firebaseService';

export const ReportComponent = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [allProjects, setAllProjects] = useState<Project[]>([]);

    const getAllBids = async () => {
        try {
            const projects = (await getAllDocuments('projects')).docs.map(e => ({ project_id: e.id, ...e.data() } as Project));
            console.log(projects);
            setAllProjects(projects.sort((a, b) => a.avenue.localeCompare(b.avenue)));
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getAllBids();
    }, [])

    if (isLoading) {
        <div>Loading...</div>
    }

    return (
        <div className="container">
            <div className="text-center">
                <h2 className="my-4">Bidding Report</h2>
            </div>
            {
                allProjects.length > 0 ? (<ul>
                    {allProjects.map(project => (
                        <li key={project.project_name} className="mb-3">{project.project_name}
                            <ol>{project.clubsOwned?.sort((a, b) => a.clubName.localeCompare(b.clubName))?.map(e => (<li key={`${project.project_id}-${e.clubId}`}>{e.clubName}</li>))}</ol>
                        </li>
                    ))}
                </ul>) : (<div>No projects to show</div>)
            }
            <ul>

            </ul>
        </div>
    );
}