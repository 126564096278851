export const avenues = [
    "Club Service", "Professional Development", "Community Service", "Public Relations", "Sports and Recreational Activities"
];

export const dummyProjects = [
    { "project_name": "Dummy One", "maximum_clubs": 4, "avenue": "Club Service", "zone": "0" },
    { "project_name": "Dummy two", "maximum_clubs": 4, "avenue": "Club Service", "zone": "0" },
    { "project_name": "Dummy three", "maximum_clubs": 4, "avenue": "Club Service", "zone": "0" },
    { "project_name": "Dummy four", "maximum_clubs": 4, "avenue": "Community Service", "zone": "0" },
    { "project_name": "Dummy five", "maximum_clubs": 4, "avenue": "Community Service", "zone": "0" },
    { "project_name": "Dummy six", "maximum_clubs": 4, "avenue": "Professional Development", "zone": "0" },
    { "project_name": "Dummy seven", "maximum_clubs": 4, "avenue": "Club Service", "zone": "0" },
    { "project_name": "Dummy eight", "maximum_clubs": 4, "avenue": "Professional Development", "zone": "0" },
    { "project_name": "Dummy nine", "maximum_clubs": 4, "avenue": "Club Service", "zone": "0" },
    { "project_name": "Dummy Ten", "maximum_clubs": 4, "avenue": "Club Service", "zone": "0" },
    { "project_name": "Dummy Eleven", "maximum_clubs": 4, "avenue": "Professional Development", "zone": "0" },
    { "project_name": "Dummy Bla bla", "maximum_clubs": 4, "avenue": "Professional Development", "zone": "0" },
    { "project_name": "Dummy ABC", "maximum_clubs": 4, "avenue": "Club Service", "zone": "0" },
    { "project_name": "Dummy Rocket", "maximum_clubs": 4, "avenue": "Club Service", "zone": "0" },
    { "project_name": "Dummy Bored", "maximum_clubs": 4, "avenue": "Club Service", "zone": "0" },
];

export const dcms = [
    { "project_name": "DCM - July", "maximum_clubs": 3, "avenue": "Club Service", "zone": "0" },
    { "project_name": "DCM - August", "maximum_clubs": 3, "avenue": "Club Service", "zone": "0" },
    { "project_name": "DCM - September", "maximum_clubs": 3, "avenue": "Club Service", "zone": "0" },
    { "project_name": "DCM - October", "maximum_clubs": 3, "avenue": "Club Service", "zone": "0" },
    { "project_name": "DCM - November", "maximum_clubs": 3, "avenue": "Club Service", "zone": "0" },
    { "project_name": "DCM - December", "maximum_clubs": 3, "avenue": "Club Service", "zone": "0" },
    { "project_name": "DCM - January", "maximum_clubs": 3, "avenue": "Club Service", "zone": "0" },
    { "project_name": "DCM - February", "maximum_clubs": 3, "avenue": "Club Service", "zone": "0" },
    { "project_name": "DCM - March", "maximum_clubs": 3, "avenue": "Club Service", "zone": "0" },
    { "project_name": "DCM - April", "maximum_clubs": 3, "avenue": "Club Service", "zone": "0" },
    { "project_name": "DCM - May", "maximum_clubs": 3, "avenue": "Club Service", "zone": "0" },
    { "project_name": "DCM - June", "maximum_clubs": 3, "avenue": "Club Service", "zone": "0" },
];

export const projects = [
    { "project_name": "RCL - Chess", "maximum_clubs": 4, "avenue": "Sports and Recreational Activities", "zone": "0" },
    { "project_name": "RCL - Carrom", "maximum_clubs": 4, "avenue": "Sports and Recreational Activities", "zone": "0" },
    { "project_name": "RCL - Badminton", "maximum_clubs": 4, "avenue": "Sports and Recreational Activities", "zone": "0" },
    { "project_name": "RCL - Table Tennis", "maximum_clubs": 4, "avenue": "Sports and Recreational Activities", "zone": "0" },
    { "project_name": "RCL - Squash", "maximum_clubs": 4, "avenue": "Sports and Recreational Activities", "zone": "0" },
    { "project_name": "RCL - Cricket", "maximum_clubs": 5, "avenue": "Sports and Recreational Activities", "zone": "0" },
    { "project_name": "RCL - Basketball", "maximum_clubs": 5, "avenue": "Sports and Recreational Activities", "zone": "0" },
    { "project_name": "RCL - Tag Rugby", "maximum_clubs": 5, "avenue": "Sports and Recreational Activities", "zone": "0" },
    { "project_name": "RCL - Futsal", "maximum_clubs": 5, "avenue": "Sports and Recreational Activities", "zone": "0" },
    { "project_name": "RCL - Netball", "maximum_clubs": 4, "avenue": "Sports and Recreational Activities", "zone": "0" },
    { "project_name": "RCL - Tug of War", "maximum_clubs": 4, "avenue": "Sports and Recreational Activities", "zone": "0" },
    { "project_name": "RCL - Athletics (Track and Field)", "maximum_clubs": 8, "avenue": "Sports and Recreational Activities", "zone": "0" },
    { "project_name": "යාலுVA", "maximum_clubs": 6, "avenue": "Community Service", "zone": "0" },
    { "project_name": "34th Rotaract District Conference", "maximum_clubs": 6, "avenue": "Professional Development", "zone": "0" },
    { "project_name": "BrandIn", "maximum_clubs": 6, "avenue": "Public Relations", "zone": "0" },
    { "project_name": "E-sports Competition", "maximum_clubs": 6, "avenue": "Sports and Recreational Activities", "zone": "0" }
];