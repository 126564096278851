import { initializeApp } from "firebase/app";
import {
    setDoc,
    doc,
    getFirestore,
    getDoc,
    onSnapshot,
    DocumentSnapshot,
    DocumentData,
    FirestoreError,
    addDoc,
    collection,
    query,
    where,
    QuerySnapshot,
    updateDoc,
    increment,
    deleteDoc,
    getDocsFromServer
} from "firebase/firestore";
import {
    set,
    ref,
    getDatabase,
    onValue,
    DataSnapshot
} from "firebase/database";

import { VERSION } from "./../constants/version_data";
import { Club } from "../models/Club";
import Project from "../models/Project";
import { User } from "../models/User";

const firebaseConfig = {
    apiKey: "AIzaSyDHmwBe2vin5psxCXDdecwh_79g4Ur9lhM",
    authDomain: "biddingplatform-8b34e.firebaseapp.com",
    projectId: "biddingplatform-8b34e",
    storageBucket: "biddingplatform-8b34e.appspot.com",
    messagingSenderId: "662428610998",
    appId: "1:662428610998:web:1a13babf7dcdc5a2eb1117",
    databaseURL: 'https://biddingplatform-8b34e-default-rtdb.asia-southeast1.firebasedatabase.app'
};

const initializeFirebase = () => initializeApp(firebaseConfig);

// Firestore functions

// User functions
export const createUser = (data: User, email: string) => setDoc(doc(getFirestore(), VERSION, 'collections', 'users', email), data);

export const getUser = (id: string) => getDoc(doc(getFirestore(), VERSION, 'collections', 'users', id));

// Club functions
export const createClub = (data: Club, id: string) => setDoc(doc(getFirestore(), VERSION, 'collections', 'clubs', id), data);

export const updateClubCoins = (clubId: string, coins: number) => updateDoc(doc(getFirestore(), VERSION, 'collections', 'clubs', clubId), { coins: increment(-coins) });

export const listenClubChange = (id: string, observerFn: {
    next: ((snapshot: DocumentSnapshot<DocumentData>) => void);
    error?: ((error: FirestoreError) => void);
    complete?: (() => void);
}) => onSnapshot(doc(getFirestore(), VERSION, 'collections', 'clubs', id), observerFn);

export const updateClub = (clubId: string, data: any) => updateDoc(doc(getFirestore(), VERSION, 'collections', 'clubs', clubId), data);

// Project functions
export const createProject = (data: Project) => addDoc(collection(getFirestore(), VERSION, 'collections', 'projects'), data);

export const listenProjectChnages = (observerFn: {
    next: ((snapshot: QuerySnapshot<DocumentData>) => void);
    error?: ((error: FirestoreError) => void);
    complete?: (() => void);
}) => onSnapshot(query(collection(getFirestore(), VERSION, 'collections', 'projects'), where('maximum_clubs', '>', 0)), observerFn);

export const updateProject = (projectId: string, data: any) => updateDoc(doc(getFirestore(), VERSION, 'collections', 'projects', projectId), data);

export const removeProject = (projectId: string) => deleteDoc(doc(getFirestore(), VERSION, 'collections', 'projects', projectId));

// Bidding functions
export const addNewBid = (data: any) => setDoc(doc(getFirestore(), VERSION, 'collections', 'bids', `${data.projectId}-${data.clubId}`), data);

export const updateBid = (bidId: string, data: any) => updateDoc(doc(getFirestore(), VERSION, 'collections', 'bids', bidId), data);

export const removeBid = (bidId: string) => deleteDoc(doc(getFirestore(), VERSION, 'collections', 'bids', bidId));

export const listenForBids = (observerFn: {
    next: ((snapshot: QuerySnapshot<DocumentData>) => void);
    error?: ((error: FirestoreError) => void);
    complete?: (() => void);
}) => onSnapshot(collection(getFirestore(), VERSION, 'collections', 'bids'), observerFn);

// Common functions 
export const getAllDocuments = (collectionName: string) => getDocsFromServer(collection(getFirestore(), VERSION, 'collections', collectionName));

// Realtime database functions
export const setCoundownTime = (startAt: number, seconds: number) => set(ref(getDatabase(), 'countdown'), { startAt, seconds });

export const listenCountdownTime = (observerFn: (snapshot: DataSnapshot) => any) => onValue(ref(getDatabase(), 'countdown'), observerFn);

export const listenServerTimeOffset = (observerFn: (snapshot: DataSnapshot) => any) => onValue(ref(getDatabase(), '.info/serverTimeOffset'), observerFn);

export default initializeFirebase;