import { useEffect, useState } from 'react';
import { serverTimestamp, Unsubscribe } from 'firebase/firestore';
import { Accordion, AccordionDetails, AccordionSummary, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import PaidIcon from '@mui/icons-material/Paid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAuth } from '../../../contexts/AuthContext';
import { avenues } from '../../../constants/projects';
import Project from '../../../models/Project';
import { addNewBid, listenProjectChnages, updateClubCoins } from '../../../services/firebaseService';

export const ProjectListComponent = (props: any) => {
    const { isActive } = props;
    const tableHeaders = ['Project Name', 'Number of Clubs', 'Max Bid', 'Bid Now'];
    const [projects, setProjects] = useState<Project[]>([]);
    const [biddingValues, setBiddingValues] = useState<any>({})
    const [myBids, setMyBids] = useState<any>({});
    const [ownedProjects, setOwnedProjects] = useState<any>({});

    const { clubData, allBiddedProjectsGrouped, myBidsGlobal, setProjectsListGlobal } = useAuth();

    useEffect(() => {
        let unsubscriber: Unsubscribe;

        unsubscriber = listenProjectChnages({
            next: (snapshot) => {
                const projectsArr = snapshot.docs.map(doc => {
                    return {
                        project_id: doc.id,
                        ...doc.data()
                    } as Project;
                });

                const tmpObj = Object.fromEntries(projectsArr.map(proj => {
                    let coinValue = 0;
                    if (biddingValues[proj.project_id || proj.project_name]) {
                        coinValue = biddingValues[proj.project_id || proj.project_name];
                    }

                    return [proj.project_id || proj.project_name, coinValue];
                }));

                setBiddingValues(tmpObj);
                setProjects(projectsArr);
                setProjectsListGlobal(projectsArr);
            },
            error: (err) => console.log(err)
        });

        return () => {
            unsubscriber && unsubscriber();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const tmpObj = biddingValues;
        myBidsGlobal.forEach(e => {
            tmpObj[e.projectId || e.projectName] = e.bid_amount;
        });
        setBiddingValues(tmpObj);
        setMyBids(Object.fromEntries(myBidsGlobal.map(e => [e.projectId || e.projectName, true])));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [myBidsGlobal]);

    useEffect(() => {
        if (clubData && clubData.projectsOwned && clubData.projectsOwned.length) {
            setOwnedProjects(Object.fromEntries(clubData.projectsOwned.map(e => [e.projectName, e])))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clubData]);

    const handleChange = (val: string, bidId: string) => {
        const formattedVal = (val === '' ? 0 : parseInt(val));
        if (formattedVal >= 0) {
            setBiddingValues({
                ...biddingValues,
                [bidId]: clubData?.coins ? (formattedVal > clubData.coins ? clubData.coins : formattedVal) : 0
            });
        }
    }

    const submitBidding = (e: any, projectId: string, projectName: string) => {
        e.preventDefault();
        const bid_amount = biddingValues[projectId || projectName] || 0
        if (clubData?.coins && bid_amount > 0 && bid_amount <= clubData?.coins) {
            const data = {
                clubId: clubData?.club_id || '',
                projectId,
                projectName,
                bid_amount,
                clubName: clubData?.c_name,
                timestamp: serverTimestamp(),
            };

            try {
                addNewBid(data);
                updateClubCoins(clubData?.club_id || '', bid_amount);
            }
            catch (e) {
                console.log(e);
            }
        }
    }

    return (
        <>
            <div className='border bg-light border-light'>
                <h4 className='mb-md-3 p-md-3 p-2'> Projects for Bidding</h4>
                {avenues.map((avenue: string) => {
                    const projectList = projects.filter((pr: Project) => pr.avenue === avenue && (pr.zone === '0' || pr.zone === clubData?.zone_id));
                    return (
                        <Accordion defaultExpanded key={avenue} className='bg-light shadow-none m-md-3'>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
                                <h5>{avenue}</h5>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TableContainer component={Paper}>
                                    <Table size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                {tableHeaders && tableHeaders.map((head: String, index: number) => {
                                                    return (
                                                        <TableCell className='px-1 px-md-3' align={index === 0 ? 'left' : 'right'} key={index}><strong>{head}</strong></TableCell>
                                                    )
                                                })}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {projectList && projectList.map((project: Project, index: number) => {
                                                return (
                                                    <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                        <TableCell className='px-1 px-md-3' style={{ width: '270px' }} component="th" scope="row">{project.project_name}</TableCell>
                                                        <TableCell className='px-1 px-md-3' align='center' component="th" scope="row">{project.maximum_clubs}</TableCell>
                                                        <TableCell className='px-1 px-md-3' align='center' component="th" scope="row">{allBiddedProjectsGrouped[project.project_name] ? allBiddedProjectsGrouped[project.project_name][0].bid_amount : 0}</TableCell>
                                                        <TableCell className='px-1 px-md-3' align='right' component="th" scope="row">
                                                            <div className='d-flex'>
                                                                <div className="input-group input-group-sm" style={{ width: '100px' }}>
                                                                    <input type="number" min={0} max={100}
                                                                        onChange={(e) => handleChange(e.target.value, project.project_id || project.project_name)}
                                                                        value={biddingValues[project.project_id || project.project_name] || ownedProjects[project.project_name]?.bid_amount || 0}
                                                                        className="form-control"
                                                                        disabled={!!(myBids[project.project_id || project.project_name] || ownedProjects[project.project_name]) || !isActive}
                                                                    />
                                                                    <div className="input-group-append">
                                                                        <button
                                                                            disabled={!!(myBids[project.project_id || project.project_name] || ownedProjects[project.project_name]) || !isActive}
                                                                            className="btn btn-sm btn-success"
                                                                            type="button"
                                                                            onClick={(e) => submitBidding(e, project.project_id || '', project.project_name)}
                                                                        >
                                                                            <PaidIcon className='p-0 m-0' style={{ fontSize: '18px' }} />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                            {!projectList.length && (
                                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell colSpan={5} className='text-center text-muted'> none to show</TableCell>
                                                </TableRow>)}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </AccordionDetails>
                        </Accordion>
                    )
                })}
            </div>
        </>
    )
}