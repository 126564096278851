import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const GuardedRoute = ({ element }: { element: JSX.Element }) => {
    const { userData } = useAuth();

    useEffect(() => { }, [userData]);

    if (userData) {
        return element;
    }

    return (<Navigate to='/login' replace />);
};

export default GuardedRoute;