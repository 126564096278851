export const club_data = [
  {
    "club_id": "1",
    "c_name": "Rotaract District 3220",
    "zone_id": "0",
  },
  {
    "club_id": "2",
    "c_name": "Rotaract Club of University of Colombo, Faculty of Management and Finance",
    "zone_id": "1",
  },
  {
    "club_id": "3",
    "c_name": "Rotaract Club of Colombo East",
    "zone_id": "1",
  },
  {
    "club_id": "4",
    "c_name": "Rotaract Club of Wattala",
    "zone_id": "1",
  },
  {
    "club_id": "5",
    "c_name": "Rotaract Club of Colombo North",
    "zone_id": "1",
  },
  {
    "club_id": "6",
    "c_name": "Rotaract Club of Centennial United",
    "zone_id": "1",
  },
  {
    "club_id": "7",
    "c_name": "Rotaract Club of Chunnakam",
    "zone_id": "3",
  },
  {
    "club_id": "8",
    "c_name": "Rotaract Club of University of Colombo, Faculty of Arts",
    "zone_id": "1",
  },
  {
    "club_id": "10",
    "c_name": "Rotaract Club of Faculty of Science, University of Colombo",
    "zone_id": "1",
  },
  {
    "club_id": "11",
    "c_name": "Rotaract Club of Alumni of University of Moratuwa",
    "zone_id": "1",
  },
  {
    "club_id": "12",
    "c_name": "Rotaract Club of College of Chemical Sciences",
    "zone_id": "1",
  },
  {
    "club_id": "13",
    "c_name": "Rotaract Club of University of Moratuwa",
    "zone_id": "1",
  },
  {
    "club_id": "14",
    "c_name": "Rotaract Club of American National College",
    "zone_id": "1",
  },
  {
    "club_id": "15",
    "c_name": "Rotaract Club of University of Kelaniya",
    "zone_id": "1",
  },
  {
    "club_id": "17",
    "c_name": "Rotaract Club of University Alumni",
    "zone_id": "1",
  },
  {
    "club_id": "19",
    "c_name": "Rotaract Club of Kandy",
    "zone_id": "2",
  },
  {
    "club_id": "20",
    "c_name": "Rotaract Club of Australian College of Business and Technology",
    "zone_id": "1",
  },
  {
    "club_id": "21",
    "c_name": "Rotaract Club of Kelaniya",
    "zone_id": "1",
  },
  {
    "club_id": "22",
    "c_name": "Rotaract Club of Colombo Regent",
    "zone_id": "1",
  },
  {
    "club_id": "23",
    "c_name": "Rotaract Club of Negombo",
    "zone_id": "1",
  },
  {
    "club_id": "24",
    "c_name": "Rotaract Club of Kurunegala",
    "zone_id": "2",
  },
  {
    "club_id": "25",
    "c_name": "Rotaract Club of University of Sri Jayewardenepura",
    "zone_id": "1",
  },
  {
    "club_id": "26",
    "c_name": "Rotaract Club of Achievers Lanka Business School ",
    "zone_id": "1",
  },
  {
    "club_id": "27",
    "c_name": "Rotaract Club of Cinnamon Gardens",
    "zone_id": "1",
  },
  {
    "club_id": "28",
    "c_name": "Rotaract Club of Singer Sri Lanka",
    "zone_id": "1",
  },
  {
    "club_id": "29",
    "c_name": "Rotaract Club of Colombo Uptown",
    "zone_id": "1",
  },
  {
    "club_id": "30",
    "c_name": "Rotaract Club of Wellawatte",
    "zone_id": "1",
  },
  {
    "club_id": "31",
    "c_name": "Rotaract Club of Chulipuram",
    "zone_id": "3",
  },
  {
    "club_id": "32",
    "c_name": "Rotaract Club of National School of Business Management",
    "zone_id": "1",
  },
  {
    "club_id": "33",
    "c_name": "Rotaract Club of CfPS Law School ",
    "zone_id": "1",
  },
  {
    "club_id": "34",
    "c_name": "Rotaract Club of Colombo",
    "zone_id": "1",
  },
  {
    "club_id": "35",
    "c_name": "Rotaract Club of Panadura",
    "zone_id": "1",
  },
  {
    "club_id": "36",
    "c_name": "Rotaract Club of University of Peradeniya",
    "zone_id": "2",
  },
  {
    "club_id": "37",
    "c_name": "Rotaract Club of Colombo West",
    "zone_id": "1",
  },
  {
    "club_id": "38",
    "c_name": "Rotaract Club of Kadawatha",
    "zone_id": "1",
  },
  {
    "club_id": "40",
    "c_name": "Rotaract Club of Pamunugama",
    "zone_id": "1",
  },
  {
    "club_id": "43",
    "c_name": "Rotaract Club of Jaffna Midtown",
    "zone_id": "3",
  },
  {
    "club_id": "44",
    "c_name": "Rotaract Club of Colombo Mid Town",
    "zone_id": "1",
  },
  {
    "club_id": "45",
    "c_name": "Rotaract Club of Nallur Heritage",
    "zone_id": "3",
  },
  {
    "club_id": "47",
    "c_name": "Rotaract Club of Informatics Institute of Technology",
    "zone_id": "1",
  },
  {
    "club_id": "48",
    "c_name": "Rotaract Club of Battaramulla",
    "zone_id": "1",
  },
  {
    "club_id": "49",
    "c_name": "Rotaract Club of Colombo Regency",
    "zone_id": "1",
  },
  {
    "club_id": "50",
    "c_name": "Rotaract Club of Colombo Mid City",
    "zone_id": "1",
  },
  {
    "club_id": "52",
    "c_name": "Rotaract Club of Sri Lanka Institute of Information Technology",
    "zone_id": "1",
  },
  {
    "club_id": "53",
    "c_name": "Rotaract Club of General Sir John Kotelawala Defence University",
    "zone_id": "1",
  },
  {
    "club_id": "54",
    "c_name": "Rotaract Club of Excellence",
    "zone_id": "1",
  },
  {
    "club_id": "57",
    "c_name": "Rotaract Club of Uva Wellassa University - Badulla",
    "zone_id": "4",
  },
  {
    "club_id": "58",
    "c_name": "Rotaract Club of Jaffna Peninsula",
    "zone_id": "3",
  },
  {
    "club_id": "62",
    "c_name": "Rotaract Club of Faculty of Law, University of Colombo",
    "zone_id": "1",
  },
  {
    "club_id": "64",
    "c_name": "Rotaract Club of Colombo Fort",
    "zone_id": "1",
  },
  {
    "club_id": "65",
    "c_name": "Rotaract Club of Jaffna",
    "zone_id": "3",
  },
  {
    "club_id": "68",
    "c_name": "Rotaract Club of Katugastota Region",
    "zone_id": "2",
  },
  {
    "club_id": "70",
    "c_name": "Rotaract Club of Peace City Hatton",
    "zone_id": "4",
  },
  {
    "club_id": "71",
    "c_name": "Rotaract Club of Colombo Central",
    "zone_id": "1",
  },
  {
    "club_id": "73",
    "c_name": "Rotaract Club of APIIT",
    "zone_id": "1",
  },
  {
    "club_id": "74",
    "c_name": "Rotaract Club of CINEC Maritime Campus",
    "zone_id": "1",
  },
  {
    "club_id": "75",
    "c_name": "Rotaract Club of Faculty of Medicine, University of Colombo",
    "zone_id": "1",
  },
  {
    "club_id": "76",
    "c_name": "Rotaract Club of General Sir John Kotelawala Defence University - Southern Campus",
    "zone_id": "4",
  },
  {
    "club_id": "77",
    "c_name": "Rotaract Club of ICBT",
    "zone_id": "1",
  },
  {
    "club_id": "79",
    "c_name": "Rotaract Club of Saegis Campus",
    "zone_id": "1",
  },
  {
    "club_id": "80",
    "c_name": "Rotaract Club of Wayamba University of Sri Lanka",
    "zone_id": "2",
  },
  {
    "club_id": "81",
    "c_name": "Rotaract Club of Rathnapura",
    "zone_id": "4",
  },
  {
    "club_id": "83",
    "c_name": "Rotaract Club of PanColombo",
    "zone_id": "1",
  },
  {
    "club_id": "85",
    "c_name": "Rotaract Club of National Institute of Business Management",
    "zone_id": "1",
  },
  {
    "club_id": "86",
    "c_name": "Rotaract Club of Athugalpura",
    "zone_id": "2",
  },
  {
    "club_id": "87",
    "c_name": "Rotaract Club of Matale",
    "zone_id": "2",
  },
  {
    "club_id": "88",
    "c_name": "Rotaract Club of Faculty of Engineering, University of Jaffna",
    "zone_id": "3",
  },
  {
    "club_id": "89",
    "c_name": "Rotaract Club of Royal Institute of Colombo",
    "zone_id": "1",
  },
  {
    "club_id": "90",
    "c_name": "Rotaract Club of Imperial College of Business Studies",
    "zone_id": "1",
  },
  {
    "club_id": "91",
    "c_name": "Rotaract Club of Trincomalee",
    "zone_id": "3",
  },
  {
    "club_id": "92",
    "c_name": "Rotaract Club of Central Campus",
    "zone_id": "1",
  },
  {
    "club_id": "93",
    "c_name": "Rotaract Club of Colombo Heritage",
    "zone_id": "1",
  },
  {
    "club_id": "95",
    "c_name": "Rotaract Club of Australian College of Business and Technology - Kandy",
    "zone_id": "2",
  },
  {
    "club_id": "96",
    "c_name": "Rotaract Club of Kandy Metropolitan",
    "zone_id": "2",
  },
  {
    "club_id": "97",
    "c_name": "Rotaract Club of Faculty of Medicine, Sabaragamuwa University of Sri Lanka",
    "zone_id": "4",
  },
  {
    "club_id": "100",
    "c_name": "Rotaract Club of Moratuwa",
    "zone_id": "1",
  },
  {
    "club_id": "101",
    "c_name": "Rotaract Club of Institute of Indigenous Medicine, University of Colombo",
    "zone_id": "1",
  },
  {
    "club_id": "102",
    "c_name": "Rotaract Club of Kilinochchi Town",
    "zone_id": "3",
  },
  {
    "club_id": "106",
    "c_name": "Rotaract Club of Colombo Reconnections",
    "zone_id": "1",
  },
  {
    "club_id": "107",
    "c_name": "Rotaract Club of Kandy Hill Capital",
    "zone_id": "2",
  },
  {
    "club_id": "108",
    "c_name": "Rotaract Club of Sri Lanka Technological Campus",
    "zone_id": "1",
  },
  {
    "club_id": "109",
    "c_name": "Rotaract Club of University of Colombo, School of Computing",
    "zone_id": "1",
  },
  {
    "club_id": "110",
    "c_name": "Rotaract Club of Senkadagala",
    "zone_id": "2",
  },
  {
    "club_id": "112",
    "c_name": "Rotaract Club of Horizon Campus",
    "zone_id": "1",
  },
  {
    "club_id": "114",
    "c_name": "Rotaract Club of Sri Lanka Telecom Training Centre",
    "zone_id": "1",
  },
  {
    "club_id": "115",
    "c_name": "Rotaract Club of University of Ruhuna",
    "zone_id": "4",
  },
  {
    "club_id": "116",
    "c_name": "Rotaract Club of Colombo Metropolitan",
    "zone_id": "1",
  },
  {
    "club_id": "117",
    "c_name": "Rotaract Club of Mannar Town",
    "zone_id": "3",
  },
  {
    "club_id": "118",
    "c_name": "Rotaract Club of Colombo Port City",
    "zone_id": "1",
  },
  {
    "club_id": "119",
    "c_name": "Rotaract Club of Rajarata University",
    "zone_id": "3",
  },
  {
    "club_id": "120",
    "c_name": "Rotaract Club of Gampaha",
    "zone_id": "1",
  },
  {
    "club_id": "201",
    "c_name": "Rotaract Club of Colombo Prime",
    "zone_id": "1",
  },
  {
    "club_id": "202",
    "c_name": "Provincial Club B",
    "zone_id": "1",
  },
  {
    "club_id": "204",
    "c_name": "Provincial Club A",
    "zone_id": "1",
  }
];
