import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const AdminGuardedRoute = ({ element }: { element: JSX.Element }) => {
    const { userData, role } = useAuth();

    useEffect(() => { }, [userData, role]);

    if (userData && role === '1') {
        return element;
    }

    if (userData) {
        return (<Navigate to='/' replace />);
    }

    return (<Navigate to='/login' replace />);
};

export default AdminGuardedRoute;