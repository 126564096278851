import React, { createContext, useContext, useEffect, useState } from 'react';
import { MD5 } from 'crypto-js';
import { Unsubscribe } from 'firebase/database';
import { Club } from '../models/Club';
import Project from '../models/Project';
import { User } from '../models/User';
import { groupBy } from '../services/adminService';
import initializeFirebase, { getUser, listenClubChange, listenForBids } from '../services/firebaseService';

interface IAuthContext {
    userData: null | User,
    role: null | string | number,
    clubData: null | Club,
    allBiddedProjects: any[];
    allBiddedProjectsGrouped: any;
    projectsListGlobal: Project[];
    setProjectsListGlobal: (projects: Project[]) => void,
    myBidsGlobal: any[],
    setMyBidsGlobal: (data: any[]) => void,
    login: (email: string, password: string, setAlertCallback?: React.Dispatch<React.SetStateAction<string>>) => Promise<void>
    logout: () => void
}

const AuthContext = createContext<IAuthContext>({
    userData: null,
    role: null,
    clubData: null,
    allBiddedProjects: [],
    allBiddedProjectsGrouped: {},
    projectsListGlobal: [],
    setProjectsListGlobal: () => { },
    myBidsGlobal: [],
    setMyBidsGlobal: () => { },
    login: async () => { },
    logout: () => { }
})

export const useAuth = () => useContext(AuthContext);

const AuthProvider = ({ children }: { children: JSX.Element }) => {
    const groupByProject = groupBy('projectName');
    const [userData, setUserData] = useState<null | User>(null);
    const [role, setRole] = useState<null | string | number>(null);
    const [clubData, setClubData] = useState<null | Club>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [allBiddedProjects, setAllBiddedProjects] = useState<any[]>([]);
    const [allBiddedProjectsGrouped, setAllBiddedProjectsGrouped] = useState<any[]>([]);
    const [projectsListGlobal, setProjectsListGlobal] = useState<Project[]>([]);
    const [myBidsGlobal, setMyBidsGlobal] = useState<any[]>([]);

    const getUserData = async () => {
        try {
            const id = localStorage.getItem('$impersonate');
            if (id) {
                const user = await getUser(atob(id));
                if (user.exists()) {
                    setUserData(user.data() as User);
                    setRole((user.data() as User).role_id);
                } else {
                    localStorage.removeItem('$impersonate');
                }
            }
        } catch (err) {
            console.log(err);
        } finally {
            setIsLoading(false);
        }
    };

    const login = async (email: string, password: string, setAlertCallback?: React.Dispatch<React.SetStateAction<string>>) => {
        try {
            const user = await getUser(email.toLowerCase());
            if (user.exists() && (user.data() as User).m_password === MD5(password).toString()) {
                setUserData(user.data() as User);
                setRole((user.data() as User).role_id);
                localStorage.setItem('$impersonate', btoa(email.toLowerCase()));
            } else {
                setAlertCallback && setAlertCallback('Invalid email or password found! Please try again.');
            }
        } catch (err) {
            console.log(err);
            setAlertCallback && setAlertCallback('Something went wrong. Please try again.');
        }
    }

    const logout = () => {
        setUserData(null);
        setRole(null);
        localStorage.removeItem('$impersonate');
    };

    useEffect(() => {
        initializeFirebase();
        getUserData();
    }, [])

    useEffect(() => {
        let unsubscribe: Unsubscribe | null = null;
        let unsubscribeBids: Unsubscribe | null = null;

        if (userData) {
            if (!unsubscribe) {
                unsubscribe = listenClubChange(userData.club_id, {
                    next: (club) => club.exists() && setClubData(club.data() as Club),
                    error: (err) => console.log('Error while listening', err)
                });
            }

            if (!unsubscribeBids) {
                unsubscribeBids = listenForBids({
                    next: (projects) => {
                        const tempProjects = projects.docs.map(e => {
                            return {
                                id: e.id,
                                ...e.data()
                            };
                        })
                        setAllBiddedProjects(tempProjects);
                        setAllBiddedProjectsGrouped(groupByProject(tempProjects));
                    },
                    error: (err) => console.log(err)
                });
            }
        }

        return () => {
            unsubscribe && unsubscribe();
            unsubscribeBids && unsubscribeBids();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData])

    if (isLoading) {
        return (
            <div className='w-100 d-flex justify-content-center' style={{ height: '100vh' }}>
                <div className="spinner-border text-danger align-self-center" role="status">
                    <span className="sr-only"></span>
                </div>
            </div>
        );
    }

    return (
        <AuthContext.Provider value={{
            userData,
            role,
            clubData,
            allBiddedProjects,
            allBiddedProjectsGrouped,
            projectsListGlobal,
            setProjectsListGlobal,
            myBidsGlobal,
            setMyBidsGlobal,
            login,
            logout
        }}>
            {children}
        </AuthContext.Provider>
    );
}

export const AuthConsumer = AuthContext.Consumer;

export default AuthProvider;