import React from 'react';
import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import Project from '../../../models/Project';
import { addPresAndSecs, addClubCoins, addProjects, startNextRound, removeAllBids } from '../../../services/adminService';
import { getAllDocuments, removeProject, setCoundownTime } from '../../../services/firebaseService';

export const BiddingControlComponent = () => {
    const [time, setTime] = React.useState<Date | null>(new Date());
    const [minutes, setMinutes] = React.useState(10);

    const setCountDown = async () => {
        try {
            await setCoundownTime((time || new Date()).getTime(), minutes * 60);
            console.log('Timer set');
        } catch (e) {
            console.log(e);
        }
    }

    const addProjectsFn = async (type: 'dummy' | 'dcm' | 'district') => {
        try {
            const projects = (await getAllDocuments('projects')).docs.map(e => ({ project_id: e.id, ...e.data() } as Project));

            let projectCount = 0;

            projects.forEach(e => {
                removeProject(e.project_id || '').then(() => {
                    projectCount++;
                }).catch(e => {
                    console.log('Something went wrong in project remove', e);
                }).finally(() => {
                    console.log(`Removed projects ${projectCount}/${projects.length}`);
                });
            })

            await addProjects(type);
        } catch (e) {
            console.log(e);
        }
    }

    const openReport = async () => {
        window.open('/report-final', '_blank');
    }

    return (
        <div className='container'>
            <div className="row mt-5">
                <div className='col-lg-8 col-12 bg-light border-0 p-4 card mb-3'>
                    <div className="row">
                        <div className="col-12 col-lg-6 mb-2">
                            <Button
                                variant='contained'
                                color='success'
                                fullWidth
                                size='large'
                                style={{ textTransform: 'none' }}
                                onClick={addPresAndSecs}>
                                <h5 className='pt-2'>Add User</h5>
                            </Button>
                        </div>
                        <div className="col-12 col-lg-6 mb-2">
                            <Button
                                variant='contained'
                                color='success'
                                fullWidth
                                size='large'
                                style={{ textTransform: 'none' }}
                                onClick={addClubCoins}>
                                <h5 className='pt-2'>Add Club Coins</h5>
                            </Button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-6 mb-2">
                            <Button
                                variant='contained'
                                color='success'
                                fullWidth
                                size='large'
                                style={{ textTransform: 'none' }}
                                onClick={() => addProjectsFn('dummy')}>
                                <h5 className='pt-2'>Add Dummy Projects</h5>
                            </Button>
                        </div>
                        <div className="col-12 col-lg-6 mb-2">
                            <Button
                                variant='contained'
                                color='success'
                                fullWidth
                                size='large'
                                style={{ textTransform: 'none' }}
                                onClick={() => addProjectsFn('dcm')}>
                                <h5 className='pt-2'>Add DCMs</h5>
                            </Button>
                        </div>
                        <div className="col-12 col-lg-6 mb-2">
                            <Button
                                variant='contained'
                                color='success'
                                fullWidth
                                size='large'
                                style={{ textTransform: 'none' }}
                                onClick={() => addProjectsFn('district')}>
                                <h5 className='pt-2'>Add District Projects</h5>
                            </Button>
                        </div>
                        <div className="col-12 col-lg-6 mb-2">
                            <Button
                                variant='contained'
                                color='success'
                                fullWidth
                                size='large'
                                style={{ textTransform: 'none' }}
                                onClick={startNextRound}>
                                <h5 className='pt-2'>Update Report</h5>
                            </Button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-6 mb-2">
                            <Button
                                variant='contained'
                                color='warning'
                                fullWidth
                                size='large'
                                style={{ textTransform: 'none' }}
                                onClick={removeAllBids}>
                                <h5 className='pt-2'>Remove All Bids</h5>
                            </Button>
                        </div>
                        <div className="col-12 col-lg-6 mb-2">
                            <Button
                                variant='contained'
                                color='info'
                                fullWidth
                                size='large'
                                style={{ textTransform: 'none' }}
                                onClick={openReport}>
                                <h5 className='pt-2'>Open Report</h5>
                            </Button>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-12 bg-light border-0 p-4 card">
                    <LocalizationProvider dateAdapter={AdapterDateFns} >
                        <DateTimePicker
                            label="Date&Time picker"
                            value={time}
                            onChange={(newValue: Date | null) => setTime(newValue)}
                            renderInput={(params: any) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                    <TextField
                        className='my-4'
                        id="minutes"
                        label="Minutes"
                        type="number"
                        inputMode="numeric"
                        value={minutes}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => parseInt(e.target.value) > 0 && setMinutes(parseInt(e.target.value))}
                    />
                    <Button
                        variant='contained'
                        size='large'
                        onClick={setCountDown}
                        style={{ textTransform: 'none' }}>
                        <h5 className='pt-2'>Set Countdown</h5>
                    </Button>
                </div>
            </div>
        </div>
    )
}